import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/messaging";

const config = {
  apiKey: "AIzaSyAy-7WBJZpXUoRd9l_7_jZCwFj9KSPf5gg",
  authDomain: "gopa-aprova.firebaseapp.com",
  projectId: "gopa-aprova",
  storageBucket: "gopa-aprova.appspot.com",
  messagingSenderId: "480220034874",
  appId: "1:480220034874:web:24a967ed6a363aead00203",
  measurementId: "G-Y5D65R5N0G"
};
const firebaseApp = firebase.initializeApp(config);
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence();
export default firebaseApp;
