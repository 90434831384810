import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import users from "./modules/users";
import shared from "./modules/shared";
import purchaseOrders from "./modules/purchaseOrders";
import purchaseRequests from "./modules/purchaseRequests";
import payments from "./modules/payments";
import historyOrders from "./modules/historyOrders";
import warehouseRequests from "./modules/warehouseRequests";
import tradeAgreement from "./modules/tradeAgreement";
import statusOrders from "./modules/statusOrders";
import contingencies from "./modules/contingencies";
import financialtitle from "./modules/financialtitle";

Vue.use(Vuex);

const modules = {
  users,
  shared,
  purchaseOrders,
  purchaseRequests,
  payments,
  historyOrders,
  warehouseRequests,
  tradeAgreement,
  statusOrders,
  contingencies,
  financialtitle
};

const persistedStates = ["users/setHasUserLoggedIn", "users/setLoggedInUser"];

const filterPersistedState = store => {
  // console.log("store.type", store.type);
  const ok = persistedStates.indexOf(store.type) >= 0;
  return ok;
};

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      filter: filterPersistedState
    })
  ],
  strict: false,
  modules: modules
});
