import firebaseApp from "@/firebase";
// import stringNow from "../../../helpers/stringNow";

const db = firebaseApp.firestore();

export async function getPayments({ commit, rootState }) {
  const { protheusUserCode } = rootState.users.loggedInUser;
  try {
    commit("shared/setLoadingMessage", "Atualizando Pagamentos", {
      root: true
    });

    const payments = [];
    const snapshot = await db
      .collection("approvalPayments")
      .where("protheusUser", "==", protheusUserCode)
      .where("status", "==", "waiting_analysis")
      .get();
    snapshot.forEach(doc => {
      payments.push({ ...doc.data(), id: doc.id });
    });

    console.info(payments);
    commit("setPayments", payments);
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Pagamentos",
        color: "red",
        position: "top"
      },
      { root: true }
    );
  }
}

export async function getPaymentsById({ commit }, paymentsId) {
  try {
    commit("shared/setLoadingMessage", "Buscando Pagamentos", {
      root: true
    });

    const payment = await db
      .collection("approvalPayments")
      .doc(paymentsId)
      .get()
      .then(doc => {
        return { ...doc.data(), id: doc.id };
      });
    commit("shared/setLoadingMessage", null, { root: true });
    return payment;
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });

    commit(
      "shared/setNotification",
      {
        message: "Falha ao buscar Pagamento",
        color: "red",
        position: "top"
      },
      { root: true }
    );
    throw new Error(error.message);
  }
}

// export async function approvesRejectsPayments({ commit, rootState }, payload) {
export async function approvesRejectsPayments({ commit }, payload) {
  const { payment, response, reasonReject } = payload;
  const { id } = payment;

  commit("shared/setLoadingMessage", "Enviando resposta. Aguarde...", {
    root: true
  });
  try {
    const paymentFb = await db
      .collection("approvalPayments")
      .doc(id)
      .get()
      .then(doc => doc.data());
    const { approvalType } = paymentFb;
    paymentFb.status = "response_sent";
    paymentFb.response = response;

    let horario = new Date(Date.now());
    let hour =
      String("0").repeat(2 - String(horario.getHours()).length) +
      horario.getHours();
    let minute =
      String("0").repeat(2 - String(horario.getMinutes()).length) +
      horario.getMinutes();
    let second =
      String("0").repeat(2 - String(horario.getSeconds()).length) +
      horario.getSeconds();

    const hora = `${hour}:${minute}:${second}`;

    // const snapshot = await db
    //   .collection("historyApproval")
    //   .where("id", "==", id)
    //   .get();

    // snapshot.forEach(async doc => {
    //   const { protheusUserCode } = rootState.users.loggedInUser;
    //   const idHistory = doc.id;

    //   let hierarchy = doc.data().approvalHierarchy;
    //   let hierarchyIndex = hierarchy.findIndex(
    //     idx => idx.protheusUser === protheusUserCode
    //   );

    //   hierarchy[hierarchyIndex].approvalDate = stringNow();
    //   hierarchy[hierarchyIndex].approvalHour = hora;
    //   hierarchy[hierarchyIndex].status =
    //     response === "approved" ? "Aprovado" : "Reprovado";

    //   await db
    //     .collection("historyApproval")
    //     .doc(idHistory)
    //     .update({
    //       approvalHierarchy: [...hierarchy]
    //     });
    // });

    const batch = db.batch();
    const setRef = db.collection("approvalPayments").doc(id);
    batch.update(setRef, {
      status: "response_sent",
      reasonRejection: reasonReject,
      response
    });

    if (approvalType === "level") {
      const querySnapshot = await db
        .collection("approvalPayments")
        .where("branchCode", "==", paymentFb.branchCode)
        .where("orderNumber", "==", paymentFb.orderNumber)
        .where("level", "==", paymentFb.level)
        .where("status", "==", "waiting_analysis")
        .get();

      querySnapshot.forEach(doc => {
        if (doc.id !== id) {
          const updateRef = db.collection("approvalPayments").doc(doc.id);
          batch.update(updateRef, {
            status: "analyzed_by_another_user",
            reasonRejection: reasonReject,
            hourApproval: hora,
            response
          });
        }
      });
    }

    await batch.commit();
    commit("shared/setLoadingMessage", null, { root: true });
  } catch (error) {
    console.error(error);
    commit("shared/setLoadingMessage", null, { root: true });
    throw new Error("Falha no envio da resposta. Tente novamente.");
  }
}
